var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","align-items":"flex-start"},attrs:{"id":"principal"}},[_c('div',{staticStyle:{"width":"150px","height":"80vh"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"}},[_c('v-btn',{staticClass:"mt-2 mb-3",staticStyle:{"text-transform":"none"},attrs:{"color":"primary","small":""}},[_vm._v(" Upload de mapa ")])],1),_c('div',{staticStyle:{"display":"flex","align-items":"baseline","justify-content":"center"}},[_c('v-text-field',{staticClass:"ml-2 mr-2",staticStyle:{"height":"20px","font-size":"12px"},attrs:{"label":"Posição","min":"14","max":"19","placeholder":"Posição","outlined":"","filled":"","background-color":"#FFF","dense":"","flat":"","tabindex":"0","type":"number"},on:{"input":_vm.aplicaPosicao},model:{value:(_vm.zoom),callback:function ($$v) {_vm.zoom=$$v},expression:"zoom"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"icon-with-text mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.aplicaPosicao()}}},on),[_vm._v(" mdi-arrow-right ")])]}}])},[_c('span',[_vm._v("Aplicar Posição")])])],1),_c('v-slider',{staticClass:"mt-4",attrs:{"min":"0","max":"4","label-color":"#FFFFFF","step":"0.01","ticks":"","vertical":"","dense":""},on:{"change":function($event){return _vm.desenha_Imagem([_vm.imageBounds.left, 
										_vm.imageBounds.bottom,
										_vm.imageBounds.right,
										_vm.imageBounds.top
										]
										)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-text-field',{staticClass:"mt-0 pt-0",staticStyle:{"width":"60px"},attrs:{"id":"text-slider","type":"number","color":"#FFFFFF"},on:{"input":function($event){return _vm.desenha_Imagem([_vm.imageBounds.left, 
											_vm.imageBounds.bottom,
											_vm.imageBounds.right,
											_vm.imageBounds.top
											]
											)}},model:{value:(_vm.imgRotacao),callback:function ($$v) {_vm.imgRotacao=$$v},expression:"imgRotacao"}})]},proxy:true}]),model:{value:(_vm.imgRotacao),callback:function ($$v) {_vm.imgRotacao=$$v},expression:"imgRotacao"}})],1),_c('div',{ref:"map",staticStyle:{"z-index":"0"},style:({ top: '44px', width: '100%', height: _vm.getHeight()+'px',  'z-index': '0' }),attrs:{"id":"map"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }